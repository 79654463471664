.email-box {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
}

.bad-result {
  margin-top: 25px;
}

.click-to-copy {
  font-weight: 800;
  margin: 25px 0px 25px 0px;
  color: #546073;
  text-decoration: underline;
  font-size: 20px;
}

.ext-link-2 {
  color: #cc6533;
  /* text-decoration: none; */
  /* border: 5px solid #8c9db9; */
  padding: 20px;
  text-shadow: 0 0 1px red;
}

.searcher-subtitle {
  margin: 5px 0px;
  font-weight: bold;
  font-size: 18px;
  color: #3f4857;
}

.email-form-top {
  display: flex;
  gap: 1rem;
  margin-top: 20px;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.email-form-top label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  font-size: larger;
  color: #8c9db9;
}

.email-form-top input {
  font: inherit;
  padding: 0.5rem;
  border: 3px solid #232f41;
  width: 35rem;
  max-width: 100%;
  background-color: black;
  color: #546073;
}

.email-form-top textarea {
  font: inherit;
  padding: 0.5rem;
  border: 3px solid #232f41;
  background-color: black;
  color: #546073;
  width: 35rem;
  max-width: 100%;
  text-align: center;
}

.mail-builder {
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
  /* align-items: center; */
  margin: 10px auto 0px auto;
}

.email-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail-builder button {
  margin: 0px 0px;
  border-radius: 10px;
}

.mail-builder textarea {
  font: inherit;
  padding: 4px;
  border: 3px solid #232f41;
  background-color: black;
  color: #8c9db9;
  width: 30rem;
  overflow: auto;
  height: 3rem;
  resize: none;
  margin: 0px 0px 10px 0px;
}

.mail-builder button {
  font: inherit;
  cursor: pointer;
  padding: 10px;
  border: 2px solid #111b28;
  background-color: #111b28;
  color: #8c9db9;
  font-weight: bold;
}

.hider {
  display: none;
}

.export-csv {
  font: inherit;
  cursor: pointer;
  padding: 5px;
  border: 2px solid white;
  background-color: #ffffff00;
  color: white;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
}

.email-form-actions button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.email-form-actions {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0px auto;
}

.email-results-wrapper {
  /* background: black; */
  padding: 10px 0px;
  border-radius: 10px;
  margin: 0px auto 5px auto;
  overflow: auto;
  max-height: 120px;
  background-color: #111b28;
  width: 80%;
}
.email-results-domain span {
  font-weight: 900;
  font-size: 22px;
  color: #8c9db9;
  border-bottom: 2px solid #222e40;
  padding: 2px 10px;
}

.email-results-domain a {
  font-weight: 900;
  font-size: 22px;
  color: white;
  border-bottom: 2px solid #222e40;
  padding: 2px 10px;
}

.sign-up-please {
  color: white;
}

.email-search-results-wrapper {
  width: 80%;
  margin: 0px auto;
}

.not-crawled {
  color: #8c9db9;
  font-weight: bold;
}

.email-swap-bulk-normal {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  font-size: larger;
  color: white;
}

.email-swap-bulk-normal a {
  text-decoration: none;
  color: white;
}

@media (max-width: 800px) {
  .mail-builder {
    /* align-items: center;
    margin: 10px auto 0px auto; */
  }
  .compare-form-title input {
    width: 80vw;
  }
  .mail-builder textarea {
    margin: 0px 0px 10px 0px;
    width: 80%;
  }
}
