.kwc-proj {
  color: white;
  background-color: #232f41;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px auto;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border-radius: 10px;
}
.kwc-proj:hover {
  background-color: grey;
}
.kwc-proj-seed {
  margin-bottom: 10px;
  color: #8c9db9;
}
.kwc-proj-seed-number {
  font-weight: bold;
  margin-bottom: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid white;
  padding: 5px;
}
.kwc-proj-kwc-done {
  color: #8c9db9;
  margin-bottom: 10px;
}
.kwc-proj-kwc-done-bool {
  font-weight: bold;
}
.kwc-proj-kwc-extracted {
  color: #8c9db9;
  margin-bottom: 10px;
}
.kwc-proj-kwc-extracted-counter {
  font-weight: bold;
  margin-bottom: 10px;
}
