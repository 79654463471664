.cb {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  margin: 20px 0 20px 0;
}

.cb-center {
  width: 80%;
  padding: 20px 20px;
  background-color: #232f41;
  color: #546073;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: auto;
  border-radius: 10px;
}

.cb-title {
  font-weight: 600;
  font-size: 22px;
  color: white;
}
.cb-description {
  font-weight: 600;
}
.cb-link {
  font-weight: 800;
  color: #009ddb;
  text-decoration: none;
}

.cb-left-arrow {
  width: 10%;
}
.cb-right-arrow {
  width: 10%;
}
.cb-arrow {
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
}

.cb-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.cb-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.cb-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.cb-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
