.App {
  text-align: justify;
}

.App-header {
  background-color: #282c34;
  height: 100vh;
}

body {
  /* display: grid; */
  /* justify-content: center; */
  align-content: center;
  height: 100vh;
  /* background-color: #282c34; */
  background: #080e16;
}

canvas {
  position: fixed;
  z-index: -1;
}

/* nice scroll bar */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #232f41 grey;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: grey;
}

*::-webkit-scrollbar-thumb {
  background-color: #232f41;
  border-radius: 20px;
  border: 2px solid grey;
}

@media only screen and (max-width: 768px) {
}
