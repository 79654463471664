.pick-projects-parent {
  overflow: auto;
  height: 100vh;
  max-height: calc(100vh - 60px);
}

.pick-projects-projects {
  display: flex;
  flex-wrap: wrap;
}

.pick-projects-title {
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.pick-projects-bar {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  margin: 10px auto 30px auto;
  color: #009ddb;
  border-bottom: 4px solid;
}

.description {
  color: #546073;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
}
