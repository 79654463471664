.OverlappedMain {
  padding: 1rem;
  margin: auto;
  width: 46rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.overlap-rate-slider {
  font-weight: bold;
  color: #8c9db9;
}

.overlap-rate-slider input[type="range"]::-webkit-slider-runnable-track {
  background: red;
}

.OverlappedMain button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.OverlappedMain button:hover,
.OverlappedMain button:active {
  background-color: #510674;
  border-color: #510674;
}

.OverlappedMain button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.OverlappedMain button.alternative:hover,
.OverlappedMain button.alternative:active {
  background-color: #ddb3f8;
}

@media only screen and (max-width: 768px) {
  .OverlappedMain {
    width: 33rem;
  }
}

@media only screen and (max-width: 576px) {
  .OverlappedMain {
    width: 20rem;
  }
}

input[type="range"] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 50%;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 50%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #232f41;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #232f41;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #232f41;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #232f41;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #232f41;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #232f41;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: #232f41;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #232f41;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #232f41;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #232f41;
}
