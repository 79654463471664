.payment-form {
  font: inherit;
  padding: 0.5rem;
  border-radius: 6px;
  border: 5px solid #ccc;
  width: 75%;
  max-width: 100%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  overflow: auto;
  max-height: 33rem;
  color: white;
}

.flexi-payment {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  margin: 2rem 0.2rem 0.2rem 0.2rem;
}

.payment-form button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #282c34;
  color: white;
  border-radius: 10px;
  font-weight: bold;
}

.payment-form button:hover,
.payment-form button:active {
  background-color: #510674;
  border-color: #510674;
}

.payment-form button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.payment-form button.alternative:hover,
.payment-form button.alternative:active {
  background-color: #ddb3f8;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #510674;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #510674;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.emailer-sub-columns {
  display: flex;
  justify-content: space-evenly;
}
.sub-column {
  width: 25%;
}
.sub-col-label {
  font-weight: 500;
  margin: 0 0 10px 0;
}

.strike-price {
  color: #aa281f;
}

@media only screen and (max-width: 900px) {
  .emailer-sub-columns {
    flex-direction: column;
  }
  .sub-column {
    margin: auto;
    width: 50%;
  }
}
