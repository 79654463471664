/* Tooltip container */
.tooltip {
  position: relative;
  /* display: inline-block; */
  /* If you want dots under the hoverable text */
  /* border-bottom: 1px dotted black;  */
  text-decoration: underline;
  margin-top: 15px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
