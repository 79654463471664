.grouping-seed {
  font-weight: 800;
  color: white;
}

.grouping-overlaps {
  font: inherit;
  font-weight: bold;
  padding: 1rem 2rem;
  color: black;
  background-color: #687fa3;
  margin: 0.2rem;
  overflow: auto;
}

.grouped-clicked-project {
  padding: 1px;
  margin: auto;
  width: 90%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.grouping-wrapper {
  /* position: absolute; */
  column-count: 4;
  column-gap: 3px;
  width: 90%;
  height: 100%;
  margin: auto;
  /* display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  align-items: flex-start; */
}

.kws-clicked-seed {
  margin: 15px auto;
  font-weight: 800;
  font-size: 20px;
  color: white;
}
