.ResultsUrlMain{
    display: flex;
    height: 100%;
    min-height: 35px;
    color: #232f41;
    text-align: center;
    overflow: hidden;
    margin: 10px;
    flex-direction: row;
}
.ResultsUrl{
    display: flex;
    background-color: #687fa3;
    width: 100%;
    max-width: 800px;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 0px;
    font-weight: bold;
}