.head-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px 0px 60px;
  color: #7a8ca9;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  max-width: 100vw;
}

.emoji-big {
  font-size: 30px;
  line-height: 0;
}

.emoji-medium {
  font-size: 18px;
  line-height: 0;
}

.head-logo {
  position: relative;
  z-index: 3;
  width: 4rem;
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 85px;
  width: 100%;
  height: calc(100vh - 60px);
  background: #080e16;
  z-index: 1;
}

.nav-nav.open {
  position: absolute;
  left: 0;
  top: 85px;
  width: 100%;
  z-index: 2;
}

.nav-nav.open .nav-links {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  /* grid-template-rows: repeat(20, 55px); */
  border-radius: 3px;
}

.nav-links {
  /* display: flex; */
}
.nav-links li {
  /* margin: 0px; */
  background-color: #080e16;
  width: 250px;
  padding: 10px 0px 10px 0px;
  text-align: center;
}

.nav-nav .separator-link {
  width: 80%;
  /* margin: 10px auto 0px auto; */
  margin: auto;
  color: #222e40;
  border-bottom: 2px solid;
  border-radius: 10px;
}

.nav-nav a {
  text-decoration: none;
  /* color: white; */
  color: #546073;
}

.current-color {
  color: white;
  font-weight: 700;
}

.current-separator {
  width: 100%;
  margin: 10px auto;
  color: #009ddb;
  border-bottom: 4px solid;
}

.tools-header {
  width: 150px;
  margin: 10px auto 0px auto;
  text-align: center;
}

.nav-nav a:hover,
.nav-nav a:active {
  color: white;
}

.nav-nav ul {
  list-style-type: none;
  list-style: none;
  padding-left: 0;
  background-color: #080e16;
  width: 250px;
  margin: auto;
  margin-top: 20px;
}

.logout-button {
  font: inherit;
  cursor: pointer;
  background-color: #ffffff00;
  color: #7a8ca9;
  border-radius: 2px;
  font-weight: bold;
  border: 0px solid white;
}

.logout-button:hover,
.logout-button:active {
  /* background-color: #0c131e; */
  color: white;
}

.highlight-link {
  font-weight: 900;
}

.mobile-nav-parent {
  width: 61px;
  display: flex;
  justify-content: center;
}

.mobile-nav {
  color: #7a8ca9;
  width: 30px;
  height: 20px;
  /* position: relative; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.mobile-nav span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #7a8ca9;
  /* color: #7a8ca9; */
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.mobile-nav span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-nav span:nth-child(2) {
  top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-nav span:nth-child(3) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-nav.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -1px;
  left: 4px;
}

.mobile-nav.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.mobile-nav.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 4px;
}

@media (min-width: 4000px) {
  .head-nav nav {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}

@media (max-width: 4000px) {
  .nav-nav {
    display: none;
  }
  .nav-nav.open {
    display: block;
  }
}

@media (max-width: 800px) {
  .head-nav {
    padding: 0px 10px 0px 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .head-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
