.generator {
  display: flex;
  overflow: hidden;
  color: white;
  min-width: 90vw;
  /* max-width: 90vw; */
}

.text-box {
  border: 5px solid white;
  border-radius: 12px;
  width: 70%;
  max-width: 70%;
  overflow: auto;
  height: 100vh;
  max-height: calc(100vh - 110px);
  padding: 20px;
  white-space: pre-wrap;
  margin-left: 4px;
}

.text-box:focus {
  outline: none;
}

.gen-info {
  width: 30%;
  max-width: 30%;
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-height: calc(100vh - 110px);
}

.gen-info button {
  margin: auto;
  margin-top: 15px;
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #ffffff00;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
}

.gen-info button:hover,
.gen-info button:active {
  background-color: #510674;
  border-color: #510674;
}

.gen-info button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.gen-info button.alternative:hover,
.gen-info button.alternative:active {
  background-color: #ddb3f8;
}

.gen-balance {
  font-weight: 600;
}

.gen-text {
  margin: 25px 0px 0px 0px;
}

.sliders-container-ai {
  margin: 30px 0px 0px 0px;
}

.sliders-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.slider-ai {
  width: 80%;
}

.knowledge-divs {
  margin: 10px 0px 0px 0px;
}

.gen-link {
  margin: 15px 0px 0px 0px;
}

.gen-link a {
  color: white;
  margin: 30px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .text-box {
    width: 100%;
    min-width: calc(100vw - 60px);
    /* margin: 0 0 0 0; */
    /* height: 100%; */
    height: 70%;
    margin: 20px 0px 0 0px;
  }
  .gen-info {
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
    min-height: calc(100vh + 120px);
    /* height: 100%;
    min-height: calc(100vh - 242px);
    max-height: 100vh; */
  }
  .generator {
    flex-wrap: wrap;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    overflow: auto;
    height: calc(100vh - 60px);
  }
  .pick-projects-title {
    font-size: 28px;
  }
}
