/* .mainBox {
  min-width: 90vw;
  max-width: 90vw;
} */
.editor {
  display: flex;
  overflow: hidden;
  color: white;
}
.editor-content {
  border: 5px solid #232f41;
  background-color: #000000;
  width: 100%;
  /* padding: 20px; */
  white-space: pre-wrap;
  height: 100vh;
  max-height: calc(100vh - 190px);
  overflow: auto;
  margin: 40px 40px 40px 40px;
  color: #546073;
}
.editor-content:focus {
  outline: none;
}

.ck-editor__editable {
  min-height: 1200px;
}

.content-suggestions {
  width: 30%;
  height: 100%;
  overflow: auto;
  height: 100vh;
  max-height: calc(100vh - 110px);
  padding: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}

.content-suggestions .top-square {
  background-color: #232f41;
  padding: 20px 0px 0px 0px;
}

.content-improver {
  max-width: 100%;
  margin: auto;
  min-height: 0;
  overflow: auto;
  background-color: #232f41;
  padding: 20px 10px 10px 10px;
}

.content-title-results {
  font-weight: 700;
}

.content-improver-separator {
  width: 70%;
  margin: 10px auto 20px auto;
  color: #009ddb;
  border-bottom: 4px solid;
}

.word-counter {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 25px auto 25px auto;
  /* border: 1px solid; */
  /* border-color: white;
  border-radius: 60px; */
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
}

.content-suggestions button {
  margin: 7px auto 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border: 5px solid #0d1521;
  background-color: #0d1521;
  color: #8c9db9;
  font-weight: bold;
  width: 70%;
  border-radius: 10px;
}

.content-suggestions button:hover,
.content-suggestions button:active {
  background-color: #510674;
  border-color: #510674;
}

.content-suggestions button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.content-suggestions button.alternative:hover,
.content-suggestions button.alternative:active {
  background-color: #ddb3f8;
}

.words {
  font-weight: 700;
  color: #546073;
}

.current-words {
  font-weight: bold;
}

.current-score {
  font-weight: bold;
  margin-bottom: 25px;
}

.total-words {
  font-weight: bold;
  color: #546073;
  margin-bottom: 5px;
}

.proj-namer {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 20px auto auto auto;
}

.words-proj {
  font-weight: bold;
  color: #546073;
}

.project-name {
  font-weight: bold;
  margin-bottom: 25px;
}

.mark {
  background-color: yellow;
}

@media only screen and (max-width: 768px) {
  .editor {
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    overflow: auto;
    height: calc(100vh - 60px);
  }
  .content-suggestions {
    width: 100%;
    overflow: unset;
    /* min-height: calc(100vh - 200px);
    max-height: calc(100vh - 309px); */
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 309px);
  }
  .content-improver {
    /* max-height: calc(100vh - 495px); */
    max-height: calc(100vh - 100px);
  }
  .content-suggestions button {
    padding: 12px 60px;
  }
  .editor-content {
    width: 100%;
    min-width: calc(100vw - 60px);
    /* margin: 0 0 0 0; */
    /* height: 100%; */
    height: 70%;
    margin: 20px 0px 0 0px;
  }
}
