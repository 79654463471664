.kwc-wrapper {
  overflow: auto;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
}

.kwclusters {
  padding: 1rem;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.kwclusters input {
  font: inherit;
  padding: 0.5rem;
  border: 4px solid #232f41;
  background-color: #000000;
  color: #546073;
  width: 35rem;
  max-width: 100%;
}

.kwclusters-bulk {
  padding: 1rem;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.kwclusters-bulk input {
  font: inherit;
  padding: 0.5rem;
  border: 3px solid #232f41;
  background-color: #000000;
  color: #546073;
  width: 35rem;
  max-width: 100%;
  height: 10rem;
  overflow: auto;
}

.bulk-keyworder {
  white-space: pre-wrap;
  font: inherit;
  padding: 0.5rem;
  border: 5px solid #232f41;
  width: 35rem;
  max-width: 100%;
  height: 10rem;
  overflow: auto;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #546073;
}

.bulk-keyworder:focus {
  outline: none;
}

.kwc-clicked-done {
  font-weight: bold;
  display: block;
  font-size: larger;
  color: #8c9db9;
}
.kwc-clicked-seed {
  font-weight: bold;
  display: block;
  font-size: larger;
  color: #8c9db9;
}

.kwc-info {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  font-size: larger;
  color: #8c9db9;
}

.kwc-swap-bulk-normal {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  font-size: larger;
  color: white;
}

.kwc-swap-bulk-normal a {
  text-decoration: none;
  color: white;
}

.kwc-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 45rem;
  margin: auto;
}

.kwc-bar-label {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0px 0px 0px;
}
.kwc-bar-separator {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  margin: 10px auto 0px auto;
  color: #009ddb;
  border-bottom: 4px solid;
  border-radius: 10px;
}

.kwc-clicked-kw {
  display: flex;
  background-color: #687fa3;
  width: 90%;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  font-weight: bold;
  height: 100%;
  min-height: 35px;
  color: #232f41;
  text-align: center;
  overflow: hidden;
  margin: 10px auto 10px auto;
  flex-direction: row;
  border-radius: 10px;
}

.kwc-clicked-kw-incomplete {
  display: flex;
  background-color: #cc6533;
  width: 90%;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  font-weight: bold;
  height: 100%;
  min-height: 35px;
  color: #232f41;
  text-align: center;
  overflow: hidden;
  margin: 10px auto 10px auto;
  flex-direction: row;
}

.kwc-overlaps {
  font-weight: bold;
  padding: 1rem 2rem;
  background-color: #687fa3;
  color: #2c394e;
  margin: 0.2rem;
  border-radius: 10px;
}

.kwc-projects {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.kwc-clcked-project {
  padding: 1px;
  margin: auto;
  width: 45rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  height: 22rem;
  overflow: auto;
}

.kwc-buttons {
  padding: 0px;
}

.kwc-buttons button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.kwc-buttons button:hover,
.kwc-buttons button:active {
  background-color: #510674;
  border-color: #510674;
}

.kwc-buttons button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.kwc-buttons button.alternative:hover,
.kwc-buttons button.alternative:active {
  background-color: #ddb3f8;
}

.kwc-horizontal button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
}

.kwc-horizontal button:hover,
.kwc-horizontal button:active {
  background-color: #510674;
  border-color: #510674;
}

.kwc-horizontalt button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.kwc-horizontal button.alternative:hover,
.kwc-horizontal button.alternative:active {
  background-color: #ddb3f8;
}

@media only screen and (max-width: 768px) {
  .kwclusters input {
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0;
  }

  .kwclusters-bulk input {
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0;
    height: 6rem;
  }

  .kwc-buttons {
    margin-bottom: 20px;
  }

  .kwc-clcked-project {
    width: 33rem;
  }

  .bulk-keyworder {
    width: 95%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .kwc-clcked-project {
    width: 20rem;
  }
}
