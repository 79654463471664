.extraction-projects {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px auto;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-color: #232f41;
}
.extraction-projects:hover {
  background-color: grey;
}

.extraction-project-namer {
  font-weight: lighter;
  margin-bottom: 5px;
  color: #8c9db9;
  text-decoration: underline;
}
.extraction-project-urls-total {
  font-weight: bold;
}
.extraction-project-date {
  font-weight: lighter;
  color: #8c9db9;
  text-decoration: underline;
}
.extraction-project-total-text {
  font-weight: lighter;
  margin-bottom: 5px;
  color: #8c9db9;
  text-decoration: underline;
}
.extraction-project-words {
  font-weight: bold;
}
