.new-project {
  padding: 1rem;
  margin: auto;
  /* width: 50rem; */
  max-width: 95%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.new-project button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.new-project button:hover,
.new-project button:active {
  background-color: #510674;
  border-color: #510674;
}

.new-project button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.new-project button.alternative:hover,
.new-project button.alternative:active {
  background-color: #ddb3f8;
}

.new-project-err-msg {
  color: white;
  margin: 5px auto;
  text-align: center;
  width: 50%;
}

@media (max-width: 576px) {
  .new-project-err-msg {
    width: 95%;
  }
}
