.text-boxer {
  border: 3px solid #232f41;
  /* border-radius: 3px; */
  width: 70%;
  max-width: 70%;
  overflow: auto;
  /* height: 100vh; */
  max-height: calc(100vh - 110px);
  padding: 20px;
  white-space: pre-wrap;
  margin: 40px 0px 40px 20px;
  color: #f2f2f2;
  background-color: #000000;
  border-radius: 10px;
}

.text-boxer:focus {
  outline: none;
}

.error-box {
  /* background: #fc2803; */
  margin: 30px 0px 0px 0px;
  color: white;
  text-shadow: 2px 2px #ff0000;
}

.emoji-hand {
  font-size: 30px;
  line-height: 0;
}

.detector-info {
  width: 30%;
  max-width: 30%;
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  max-height: calc(100vh - 110px);
  color: #8c9db9;
}

.detector-info h3 {
  color: white;
}

.detector-bars {
  height: 30px;
  padding: 0px 10px 0px 10px;
  margin-bottom: 30px;
}

.detector-bars td {
  padding: 0px;
}

.detector-bars .bar-row {
  height: 30px;
}

#real-percentage {
  text-align: left;
  vertical-align: bottom;
  width: 10%;
}

#fake-percentage {
  text-align: right;
  vertical-align: bottom;
  width: 10%;
}

#bar-container {
  width: 60%;
  background-color: #cc6533;
  line-height: 0.5;
  position: relative;
  top: 6px;
  border-radius: 6px;
  border: 1px solid #03a6e0;
}

#bar {
  height: 30px;
  background-color: #02a4df;
  /* border-radius: 8px; */
}

#top-table {
  vertical-align: bottom;
}

#real {
  text-align: left;
}
#dtct-message {
  text-align: center;
}
#fake {
  text-align: right;
}

.final-result-real {
  width: 60%;
  margin: auto;
  padding: 5px;
  border-radius: 12px;
  background: #02a4df;
  color: black;
}

.final-result-fake {
  width: 60%;
  margin: auto;
  padding: 5px;
  /* border-radius: 12px; */
  background: #cc6533;
  color: black;
}

.detector-info button {
  margin: auto;
  margin-top: 40px;
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: #232f41;
  color: #8c9db9;
  border: 0px solid #232f41;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
}

.detector-info button:hover,
.detector-info button:active {
  background-color: #510674;
  border-color: #510674;
}

.detector-info button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.detector-info button.alternative:hover,
.detector-info button.alternative:active {
  background-color: #ddb3f8;
}

@media only screen and (max-width: 768px) {
  .text-boxer {
    width: 100%;
    min-width: calc(100vw - 60px);
    /* margin: 0 0 0 0; */
    /* height: 100%; */
    height: 70%;
    margin: 20px 0px 0px 0px;
  }
  .detector-info {
    width: 100%;
    max-width: 100%;
    margin-top: 25px;
    min-height: calc(100vh + 120px);
    /* height: 100%;
      min-height: calc(100vh - 242px);
      max-height: 100vh; */
  }
  .generator {
    flex-wrap: wrap;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    overflow: auto;
    height: calc(100vh - 60px);
  }
  .pick-projects-title {
    font-size: 28px;
  }
}
