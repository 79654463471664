.detection-logs-parent {
  text-align: center;
  font-size: 18px;
  color: white;
  justify-content: center;
}

.indicators {
  display: flex;
  color: #f2f2f2;
  text-align: center;
  overflow: hidden;
  margin: 0px auto 10px auto;
  flex-direction: row;
  font-size: 16px;
}
.indicators .ind-domain {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 0px 2px 0px 2px;
}
.indicators .ind-url {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.Results-main {
  display: flex;
  height: 100%;
  min-height: 30px;
  color: black;
  text-align: center;
  overflow: hidden;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: row;
  font-size: 16px;
  width: 90%;
}

.Results-main-emails {
  display: flex;
  height: 100%;
  min-height: 30px;
  color: #232f41;
  text-align: center;
  overflow: hidden;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: row;
  font-size: 16px;
  width: 90%;
  border-radius: 10px;
}

.Results-main-emails .email-log {
  display: flex;
  background-color: #687fa3;
  width: 80%;
  align-items: center;
  justify-content: center;
  border: 5px #687fa3 solid;
  color: #232f41;
  font-weight: bold;
}

.Results-main-emails .email-log .social-result {
  margin-left: calc(100vw - 150%);
}

.Results-main-emails .mailtoicon {
  display: flex;
  background-color: #687fa3;
  width: 20%;
  align-items: center;
  justify-content: center;
  /* border: 5px #445b8c solid; */
  color: white;
}

.Results-main .domain {
  display: flex;
  background-color: #687fa3;
  color: #232f41;
  width: 40%;
  align-items: center;
  justify-content: center;
  padding: 0px 2px 0px 2px;
  font-weight: bold;
  /* border: 5px #232f41 solid; */
}

.Results-main .domain:hover {
  border: 5px #009ddb solid;
  /* border-color: #009ddb; */
}

.Results-main .title {
  display: flex;
  background-color: #232f41;
  color: #687fa3;
  width: 60%;
  align-items: center;
  justify-content: center;
  border: 5px #232f41 solid;
}

.Results-main .email-log {
  display: flex;
  background-color: #445b8c;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 5px #445b8c solid;
  color: white;
}

.Results-main .phones-log {
  display: flex;
  background-color: #b7cdf0;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #232f41;
  font-weight: bold;
  border-radius: 10px;
}

.Results-main .socials-log {
  display: flex;
  background-color: #687fa3;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #232f41;
  font-weight: bold;
  border-radius: 10px;
}

.Results-main .title:hover {
  border-color: #009ddb;
}

.Results-main .socials-log:hover {
  border-color: #009ddb;
}

.Results-main .phones-log:hover {
  border-color: #009ddb;
}

.Results-main .email-log:hover {
  border-color: #009ddb;
}

@media (max-width: 999px) {
  .Results-main {
    width: 100%;
    font-size: 0.9rem;
  }
  .Results-main-emails {
    width: 100%;
    font-size: 0.9rem;
  }
  .Results-main .title {
    width: 75%;
  }
  .Results-main .domain {
    width: 25%;
  }
  .Results-main-emails .email-log .social-result {
    margin-left: calc(100vw - 130%);
  }
}

.separator {
  border: 2px solid white;
  margin: 15px auto 15px auto;
  width: 80vw;
}

.nav-button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  font-weight: bold;
  margin: 0px 10px 0px 10px;
  border-radius: 10px;
}

.nav-button:hover,
.nav-button:active {
  background-color: #009ddb;
  border-color: #009ddb;
}

.search-results-parent {
  overflow: auto;
  height: 70vh;
}

.previous-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.current-page {
  font-weight: 800;
  border: 5px #02a4df solid;
  padding: 12px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.error-message {
  margin-top: 25px;
  color: #546073;
}

.lds-hourglass {
  /* display: inline-block; */
  position: static;
  margin: 0px auto 0px auto;
  /* margin-top: 50px; */
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.cont-cont {
  display: none;
}

.cont-cont.opend {
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 30vw;
  height: 70vh;
  z-index: 99;
  display: block;
  border: 5px solid #687fa3;
  background-color: #282c34;
  color: #f2f2f2;
  padding: 10px 10px 10px 10px;
  overflow: auto;
}

.title.clicked-cont {
  background-color: #007eb0;
}

.social-result {
  display: flex;
  flex-direction: row;
}

.social-icon {
  height: 1.5rem;
  margin: 0px 5px 0px 5px;
}
