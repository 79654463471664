.pick-text-extractor-projs {
  display: flex;
  flex-wrap: wrap;
}

.projects-copy-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.extraction-wrapper {
  text-align: center;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  height: 100vh;
  max-height: calc(100vh - 110px);
  min-height: calc(100vh - 110px);
}

.extract-form-top {
  display: flex;
  gap: 1rem;
  margin-top: 50px;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.extract-form-top label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  font-size: larger;
  color: #8c9db9;
}

.extract-form-top .extract-form-editor {
  font: inherit;
  padding: 0.5rem;
  border: 2px solid #232f41;
  background-color: #000;
  color: #8c9db9;
  width: 35rem;
  max-width: 100%;
}

.extract-form-editor {
  margin: auto;
  padding: 20px;
  overflow: auto;
  max-height: 10rem;
  background-color: white;
  color: black;
}

.extract-form-editor:focus {
  outline: none;
}

.results-not-editor {
  font: inherit;
  padding: 0.5rem;
  background-color: #0d1521;
  width: 90%;
  max-width: 100%;
  margin: 10px auto 0px auto;
  padding: 20px;
  overflow: auto;
  max-height: 33rem;
  color: #546073;
}

.results-not-editor:focus {
  outline: none;
}

.display-linebreak {
  white-space: pre-line;
}

.extractor-projects-bar {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  margin: 10px auto 30px auto;
  color: white;
  border-bottom: 4px solid;
  border-radius: 10px;
}

.extract-form-actions {
  /* display: flex;
  text-align: center;
  justify-content: center;
  margin: auto; */
}

.more-than-20 {
  font-weight: bold;
  color: white;
}

.extraction-wrapper button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.extraction-wrapper button:hover,
.extract-form-actions button:active {
  background-color: #510674;
  border-color: #510674;
}

.extraction-wrapper button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.extraction-wrapper button.alternative:hover,
.extraction-wrapper button.alternative:active {
  background-color: #ddb3f8;
}

.extraction-wrapper button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
}

.extraction-wrapper button:hover,
.extraction-wrapper button:active {
  background-color: #510674;
  border-color: #510674;
}

.extraction-wrapper button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.extraction-wrapper button.alternative:hover,
.extraction-wrapper button.alternative:active {
  background-color: #ddb3f8;
}

.extract-buttons-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .extract-form-top .extract-form-editor {
    width: 100%;
    box-sizing: border-box;
  }

  .display-linebreak {
    box-sizing: border-box;
  }
}
