.keywordMain {
  display: flex;
  height: 100%;
  min-height: 35px;
  color: #232f41;
  text-align: center;
  overflow: hidden;
  margin: 10px;
  flex-direction: row;
  border-radius: 10px;
}
.keyword {
  display: flex;
  background-color: #687fa3;
  width: 60%;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 0px 5px;
  font-weight: bold;
}
.current {
  display: flex;
  background-color: #b7cdf0;
  width: 20%;
  align-items: center;
  justify-content: center;
  color: #232f41;
}
.ideal {
  display: flex;
  background-color: #b7cdf0;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #232f41;
}
