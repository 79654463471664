.login-main {
  display: flex;
  overflow: auto;
  color: white;
  min-width: 70vw;
  max-width: 70vw;
  margin: 5px auto;
}

.gradient-bar {
  color: #02a4df;
  background: linear-gradient(
    90deg,
    rgba(13, 21, 33, 1) 0%,
    rgba(29, 42, 62, 1) 50%,
    rgba(26, 72, 88, 1) 100%
  );
  /* width: 99%; */
  padding: 0px 5px 0px 5px;
  font-size: 72px;
  font-weight: 900;
  font-family: sans-serif, verdana;
  top: 0;
  position: static;
}

.thirty-three-one {
  width: 50%;
  max-height: 100%;
  height: 100%;
  justify-content: space-between;
  align-content: center;
  text-align: left;
  margin: auto;
  padding: 0px 0px 0px 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 2;
  color: #8c9db9;
}

.h1-white {
  color: white;
  line-height: 25px;
}

.h1-white-reverse {
  color: black;
  line-height: 0px;
  background-color: white;
  padding: 5px 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.thirty-three-three {
  width: 50%;
  /* min-width: 50%; */
  /* max-width: 50%; */
  height: 100%;
  overflow: auto;
  /* padding: 0.5rem; */
  text-align: center;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  max-height: 100%;
  font-weight: 700;
}

.user-counts-login {
  text-align: center;
  font-weight: 300;
  color: #8c9db9;
}

.bolding-login {
  font-weight: 500;
  color: white;
}

.login-logo {
  width: 40%;
}
.login-kazano {
  /* max-height: 75%; */
  height: 75%;
  max-width: 75%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.login-form-top {
  margin-bottom: 2rem;
}

.login-form-fields {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form-fields label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  color: #03a6e0;
}

.login-form-buttons {
  margin-bottom: 2rem;
}

.login-form-warning {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
}

.login-form-warning a {
  text-decoration: none;
  color: #03a6e0;
}

.login-form-warning-blue {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  display: block;
  color: #fc532f;
}

.login-form button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.login-form button:hover,
.login-form button:active {
  background-color: #510674;
  border-color: #510674;
}

.login-form button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.login-form button.alternative:hover,
.login-form button.alternative:active {
  background-color: #ddb3f8;
}

.login-form-fields input {
  font: inherit;
  padding: 0.5rem;
  border: 2px solid #232f41;
  width: 60%;
  max-width: 100%;
  background: #000000;
  color: #8c9db9;
}

.pointer-link:hover {
  cursor: pointer;
}

.front-second-section {
  color: #8c9db9;
  width: 50%;
  margin: 40px auto;
  text-align: center;
  line-height: 35px;
  padding: 50px 0px 50px 0px;
  background-color: rgba(13, 21, 33, 1);
  border: 2px solid #232f41;
  border-radius: 10px;
}
.bolding-second-section {
  font-weight: 900;
  color: #02a4df;
}
.bolding-second-last-section {
  font-weight: 900;
}

.front-third-section {
  margin: auto;
  text-align: center;
  font-weight: 900;
  color: white;
}

.front-fourth-section {
  margin: 40px auto;
  text-align: center;
  font-weight: 900;
  color: white;
}

.front-fourth-section p {
  color: #8c9db9;
  line-height: 30px;
}

.front-fifth-section {
  margin: 40px auto;
  /* background-color: #232f41; */
  background: linear-gradient(
    90deg,
    rgba(13, 21, 33, 1) 0%,
    rgba(29, 42, 62, 1) 50%,
    rgba(26, 72, 88, 1) 100%
  );
  text-align: justify;
  color: white;
  font-weight: 900;
}

.front-fifth-section div {
  padding: 50px 50px;
  width: 60%;
  margin: auto;
}

.front-fifth-section h2 {
  font-size: 28px;
}

.front-fifth-section p {
  color: #8c9db9;
  line-height: 30px;
}

.front-sixth-section {
  color: #8c9db9;
  width: 50%;
  margin: 100px auto;
  text-align: justify;
  line-height: 35px;
  padding: 50px 0px 50px 0px;
  background-color: rgba(13, 21, 33, 1);
  border: 2px solid #232f41;
  border-radius: 10px;
}
.front-sixth-section div {
  width: 60%;
  margin: auto;
}
.bolding-sixth-section {
  font-weight: 900;
  color: #02a4df;
}

.front-seventh-section {
  margin: 40px auto;
  text-align: center;
  font-weight: 900;
  color: white;
  padding-bottom: 100px;
}

.front-seventh-section h2 {
  font-size: 48px;
}

.front-seventh-section p {
  color: #8c9db9;
  line-height: 30px;
}

.front-final-button button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  border-radius: 5px;
  background-color: #232f41;
  color: white;
  margin: 0.2rem;
  font-weight: bold;
}

@media (max-width: 576px) {
  .app-logo-login {
    width: 69%;
  }
  .login-main {
    /* width: 100%; */
    /* flex-direction: row; */
    flex-wrap: wrap;
    /* overflow: auto; */
    margin: 40px auto 0px auto;
    /* margin-top: 35px; */
    /* height: 100vh; */
    min-width: 100vw;
  }
  .thirty-three-one {
    width: 100%;
    justify-content: space-between;
    align-content: left;
    text-align: left;
    height: auto;
    min-height: auto;
    max-height: auto;
  }
  .thirty-three-two {
    width: 100%;
    justify-content: space-between;
    align-content: center;
    text-align: center;
    height: auto;
    min-height: auto;
    max-height: auto;
    margin: auto;
  }
  .thirty-three-three {
    width: 100%;
    justify-content: space-between;
    align-content: center;
    text-align: center;
    height: auto;
    min-height: auto;
    max-height: auto;
    margin: auto;
  }

  .gradient-bar {
    top: 0;
    font-size: 48px;
  }

  .front-second-section {
    width: 95%;
  }

  .front-fifth-section div {
    width: 95%;
    padding: 0px;
  }

  .front-sixth-section {
    width: 95%;
  }
}
