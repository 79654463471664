.forgot-main {
  padding: 1rem;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.forgot-logo {
  max-height: 20rem;
}

.forgot-main-top {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.forgot-main-fields {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.forgot-main-fields label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  color: white;
}

.forgot-main-warning {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  color: white;
}

.forgot-main-warning-blue {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  display: block;
  color: #fc532f;
}

.forgot-main button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #ffffff00;
  color: white;
  border-radius: 10px;
  margin: 0.2rem;
  font-weight: bold;
}

.forgot-main button:hover,
.forgot-main button:active {
  background-color: #510674;
  border-color: #510674;
}

.forgot-main button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.forgot-main button.alternative:hover,
.forgot-main button.alternative:active {
  background-color: #ddb3f8;
}

.forgot-main-fields input {
  font: inherit;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
  margin-bottom: 1rem;
}

.forgot-back-to-login {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

@media (max-width: 576px) {
  .forgot-main-fields input {
    width: 15rem;
  }
  .forgot-logo {
    max-height: 15rem;
  }
}
