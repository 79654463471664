.project-form-form{
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.project-form-top {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .project-form-top label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    color: #546073;
  }
  
  .project-form-top input {
    font: inherit;
    padding: 0.5rem;
    background-color: #000000;
    border: 5px solid #232f41;
    width: 20rem;
    max-width: 100%;
    color: #425165;
  }

  .project-form-actions{
    display: flex;
    text-align: center;
    justify-content: center;
    margin: auto;
  }