.emoji-big-d {
  font-size: 22px;
  line-height: 0;
}

.detector-alerts {
  font: inherit;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #e31c16;
  color: white;
  border-radius: 12px;
  margin: 0.2rem;
  font-weight: bold;
}

.detector-alerts-blank {
}

.progressbar {
  text-align: center;
  background-color: red;
  padding: 0px;
  position: relative;
  height: 35px;
  margin: 10px;
  border-radius: 50px;
}

.progressbar-div {
  background-color: green;
  height: 35px;
  border-radius: 50px;
}

.progressbar-div-pending {
  background-color: grey;
  height: 35px;
  border-radius: 50px;
}

.progress-label {
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  text-shadow: 0 0 2px #fff;
}

.progress-label button {
  padding: 0px;
  margin: 0px;
  border: 0px solid;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .progressbar {
    height: 70px;
    width: 99%;
  }

  .progressbar-div {
    height: 70px;
    width: 99%;
  }

  .progressbar-div-pending {
    height: 70px;
    width: 99%;
  }
}
