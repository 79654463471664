.project-creation button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #282c34;
  color: white;
  border-radius: 12px;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.project-creation-text {
  color: white;
}

.project-creation {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
