.project {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px auto;
  background-color: #232f41;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border-radius: 10px;
}
.project:hover {
  background-color: grey;
}

.project-namer {
  /* font-weight: lighter; */
  /* border-bottom: 2px solid; */
  margin-bottom: 5px;
  color: #8c9db9;
}
.project-title {
  font-weight: bold;
  margin-bottom: 5px;
  border: 2px solid white;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.project-keywords {
  /* font-weight: lighter; */
  color: #8c9db9;
  margin-bottom: 5px;
}
.project-keywords-number {
  font-weight: bold;
}
