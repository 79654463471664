.compare-wrapper {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
}

.compare-form-top {
  display: flex;
  gap: 1rem;
  margin-top: 20px;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
}

.compare-form-top label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
  font-size: larger;
  color: #8c9db9;
}

.compare-form-top input {
  font: inherit;
  padding: 0.5rem;
  border: 2px solid #232f41;
  width: 35rem;
  max-width: 100%;
  background-color: #000;
  color: #8c9db9;
}

.compare-form-actions {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.compare-results {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5rem;
  display: block;
}

.compare-form-actions button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #232f41;
  background-color: #232f41;
  color: #8c9db9;
  margin: 0.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.compare-word-counter {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 25px auto 25px;
  width: 15rem;
  height: 120px;
  background-color: #232f41;
}

.compare-words {
  font-weight: bold;
  font-size: 20px;
  color: #8c9db9;
  text-decoration: underline;
}

.compare-current-words {
  font-weight: bold;
  margin: 10px 0px 0px 0px;
}

.compare-repeated-urls {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #8c9db9;
  font-weight: bold;
  font-size: large;
  margin-bottom: 0.5rem;
  display: block;
}

.compare-form-actions button:hover,
.compare-form-actions button:active {
  background-color: #510674;
  border-color: #510674;
}

.compare-form-actions button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.compare-form-actions button.alternative:hover,
.compare-form-actions button.alternative:active {
  background-color: #ddb3f8;
}

.compare-credit {
  margin-top: 10px;
  color: #546073;
  width: 80%;
  margin: 0px auto 0px auto;
}

.compare-credit a {
  color: #546073;
  text-decoration: none;
  font-weight: bold;
}

.compare-instructions {
  color: #546073;
}

@media only screen and (max-width: 768px) {
  .compare-form-top input {
    width: 100%;
    box-sizing: border-box;
  }

  .compare-form-actions button {
    padding: 10px 30px;
  }
}
