.main-landing {
  display: flex;
  overflow: auto;
  color: white;
  min-width: 98vw;
  max-width: 98vw;
}

.landing-animation {
  border: 5px solid white;
  border-radius: 12px;
  width: 50%;
  /* min-width: 50%; */
  /* max-width: 50%; */
  height: 100%;
  overflow: auto;
  min-height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  padding: 20px;
  white-space: pre-wrap;
}

.landing-signup {
  width: 50%;
  /* min-width: 50%; */
  /* max-width: 50%; */
  height: 100%;
  overflow: auto;
  padding: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}

@media only screen and (max-width: 768px) {
  .landing-animation {
    width: 100%;
    /* height: 50vh; */
    min-height: 50vh;
    max-height: 50vh;
  }
  .landing-signup {
    width: 100%;
    margin-top: 35px;
  }
  .main-landing {
    flex-wrap: wrap;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    height: 100vh;
  }
}

.main-landing button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid white;
  background-color: #ffffff00;
  color: white;
  border-radius: 10px;
  margin: 0rem;
  font-weight: bold;
}

.main-landing button:hover,
.main-landing button:active {
  background-color: #510674;
  border-color: #510674;
}

.main-landing button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.main-landing button.alternative:hover,
.main-landing button.alternative:active {
  background-color: #ddb3f8;
}

.landing-logo {
  max-width: 15rem;
}

.landing-form-fields {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.landing-form-fields label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  color: white;
}

.landing-form-fields input {
  font: inherit;
  padding: 0.5rem;
  border: 3px solid #080e16;
  background-color: black;
  color: white;
  width: 20rem;
  max-width: 100%;
}
.landing-form-buttons {
  margin: 20px 0px 20px 0px;
}

.landing-phrase {
  font-weight: bold;
  margin-top: 20px;
}

.landing-button-hidden {
  display: none;
}

.landing-acc-created-hidden {
  display: none;
}
