.ev-proj {
  color: white;
  background-color: #232f41;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px auto;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}
.ev-proj-seed {
  margin-bottom: 10px;
  color: #8c9db9;
}
.ev-proj-seed-number {
  font-weight: bold;
  margin-bottom: 10px;
}
.ev-proj-ev-done {
  color: #8c9db9;
  margin-bottom: 10px;
}
.ev-proj-ev-done-bool {
  font-weight: bold;
}
.ev-proj-ev-extracted {
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  border: 2px solid white;
  padding: 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ev-proj-ev-extracted-counter {
  font-weight: bold;
  margin-bottom: 10px;
}
.pointer-link:hover {
  cursor: pointer;
}
.ev-export {
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.ev-export img {
  filter: brightness(0) invert(1);
}

.ev-error {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  display: block;
  font-size: larger;
  color: #f5713d;
}

.kwclusters textarea {
  font: inherit;
  padding: 0.5rem;
  border: 3px solid #232f41;
  background-color: black;
  color: #546073;
  width: 35rem;
  max-width: 100%;
  text-align: center;
}

.refresh-image img {
  filter: brightness(0) invert(1);
}
