.refill {
  /* padding: 1rem; */
  color: #f2f2f2;
  /* text-align: center;
  align-items: center; */
  justify-content: space-between;
  overflow: auto;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(168, 239, 255, 0.1);
}

.refill button {
  margin: 15px 0px 0px 0px;
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 5px solid #080e16;
  background-color: #080e16;
  color: #8c9db9;
  font-weight: bold;
  /* border: 0.35rem solid red; */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
  border-radius: 10px;
}

.button-border {
  border: 0.35rem solid red;
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}
@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

.slidecontainer {
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 16px;
  background: gray;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 20px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: green;
  cursor: pointer;
  border: 2px solid green;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: green;
  cursor: pointer;
}

.refill-square {
  border: 5px solid #02a4df;
  width: 80%;
  overflow: auto;
  padding: 10px 40px 10px 40px;
  white-space: pre-wrap;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: black;
  /* text-align: left; */
}

.signupper {
  text-align: center;
  align-items: center;
  background-color: #232f41;
  /* border: 2px solid #8c9db9; */
  width: 100%;
  margin: auto;
  padding: 20px 0px 20px 0px;
}

.landing-form-fields {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.landing-form-fields label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
  color: white;
}

.landing-form-fields input {
  font: inherit;
  padding: 0.5rem;
  border: 1px solid #080e16;
  width: 20rem;
  max-width: 100%;
}

.ext-link {
  color: #cc6533;
  text-decoration: none;
  /* border: 5px solid #8c9db9; */
  padding: 20px;
  /* text-shadow: 0 0 3px orangered; */
}

.ext-link-message {
  color: red;
  text-decoration: red;
  padding: 20px;
  text-shadow: 0 0 3px red;
}

.sign-up-form-title {
  color: #009ddb;
  text-decoration: #009ddb;
  padding: 10px;
  font-size: 18px;
  text-shadow: 0 0 3px #009ddb;
}

.ext-link-h2 {
  margin: 40px 0 40px 0;
}
.refill .cta-extension {
  margin: 0px 0px 30px 0px;
}

.login-kazano-r {
  margin: 20px 0px 20px 0px;
  height: 6rem;
  animation: float 6s ease-in-out infinite;
}

.center-kazano {
  text-align: center;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.table {
  width: 100%;
  max-width: 700px; /* Adjust based on your preference */
  margin: 0 auto 25px auto;
  border-collapse: separate;
  border-spacing: 20px 5px;
}
.table th {
  background-color: #009ddb;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  /* width: 50%; */
}
.table td,
.table th {
  padding: 10px;
  border: 1px solid #fff;
  text-align: center;
  width: 50%;
}
.table tr:nth-child(even) {
  background-color: #232f41;
}
.table tr:nth-child(odd) {
  background-color: #1b2534;
}
.table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}
.table tr:first-child th:last-child {
  border-top-right-radius: 5px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.disclaimer {
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 100;
  font-size: 14px;
  font-weight: bold;
  color: #8c9db9;
}

.subtitle-exp {
  font-weight: 100;
  font-size: 14px;
  color: #8c9db9;
}

.subtitle-exp-2 {
  font-weight: bold;
  font-size: 14px;
  color: #546073;
}

.nst {
  color: #009ddb;
}
.normal-st {
  color: white;
}

.sub-margin {
  margin: -20px 0px 0px 0px;
}

.kazanway {
  text-align: left;
  margin: 50px 0px 50px 0px;
  background-color: #0d1521;
  font-weight: 700;
  font-size: 24px;
  padding: 20px 10px 20px 10px;
  border: 2px solid #02a4df;
}

.offer-expiry {
  background-color: #cc6533;
  padding: 10px;
}

.wrap-slider-labels {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.slider-emails {
  width: 100%;
  margin: 15px auto 0px auto;
}
.slider-boundaries {
  margin: auto;
  text-align: center;
}
.slider-section {
  margin: 50px 0px;
}

.slider-pointing {
  margin-top: 50px;
  text-align: center;
  font-size: 22px;
}
.h3-sub {
  color: #aeaeae;
  margin-bottom: 50px;
}

/* white space */
.ws {
  white-space: pre-wrap;
}

.h3-sub2 {
  color: #aeaeae;
  margin: 50px 0px;
}

.h3-center {
  text-align: left;
  margin: 60px 0px 0px 0px;
}
.emoji-color {
  color: transparent;
  text-shadow: 0 0 0 #546073;
}

.white-strong {
  color: #fff;
}

.blue-strong {
  color: #02a4df;
}

.gray-p {
  color: #8c9db9;
}

.center-things {
  text-align: center;
  margin: 0px 0px 30px 0px;
}
.center-things button {
  margin: 0px 0px 10px 0px;
}

@media (max-width: 1000px) {
  .refill-square {
    width: calc(100% - 18px);
    padding: 5px;
    border: 5px solid #02a4df;
    overflow: hidden;
    margin: 30px 0px 30px 0px;
    /* margin-top: 30px;
    margin-bottom: 30px; */
  }
  .signupper {
    width: 100%;
  }
  .refill {
    padding: 0px 50px 0px 50px;
  }
  .slidecontainer {
    width: calc(100% - 18px);
    margin: 0px;
  }

  .table {
    border-spacing: 6px 2px;
  }

  .kazanway {
    padding: 20px 3px 20px 3px;
    font-size: 22px;
    font-weight: 400;
  }
  .slider-pointing {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .refill {
    padding: 0px;
    /* justify-content: space-evenly; */
    /* overflow: auto; */
  }
  .wrap-slider-labels {
    flex-direction: column;
    row-gap: 10px;
  }
}
