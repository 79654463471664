.mainVerify {
  padding: 1rem;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
}
